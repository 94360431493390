import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const SectionMapPage = ({ location }) => {
//  const { allSectionsYaml } = useStaticQuery(sectionsData)
  return (
         <Layout {...{ location }}>
             <div className="card-title" align="center">
                 If you're looking for the Section Map you've come to the right place.
                 <br></br>We've taken the map down in favor of getting you access to accurate data.
                 <p><br></br>To find Section websites and addresses
                 <p> Click <a target="_blank" rel="noopener noreferrer" href="https://directory.pga.org/?refinementList%5Bfacility_type_label%5D%5B0%5D=PGA%20Section%20Office">HERE</a></p>
                 For all your PGA Officer reports
                 <p> Click <a target="_blank" rel="noopener noreferrer" href="https://pgahq.cloud.looker.com/dashboards-next/462">HERE</a></p>
                 </p>
                 <br></br> Feel free to bookmark these links!
             </div>
         </Layout>
/*          <Layout {...{ location }}>
              <MediaQuery minWidth={992}>
                  <SectionMap allSectionsYaml={allSectionsYaml} />
              </MediaQuery>
              <MediaQuery maxWidth={991}>
                  <MobileSectionMap allSectionsYaml={allSectionsYaml} />
              </MediaQuery>
          </Layout>
*/
  )
}

export const sectionsData = graphql`
  query {
    allSectionsYaml {
      edges {
        node {
          Address
          District
          Executive_Director
          Executive_Director_Email
          Phone_Number
          President
          Secretary
          Section
          URL
          Vice_President
          attr
          Executive_Director_Email
          Executive_Director_Phone_Number
          President_Email
          President_Phone_Number
          Secretary_Phone_Number
          Secretary_Email
          Vice_President_Email
          Vice_President_Phone_Number
          polygon
          text
        }
      }
    }
  }
`

export default SectionMapPage

